import React, { Fragment } from "react";

export const CLIENT = [
  {
    pretitle: "DATA & SECURITY",
    title: "How does Fieldscope protect my data?",
    content: (
      <Fragment>
        Fieldscope uses{" "}
        <a
          href="https://cloud.google.com/security/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Cloud Storage
        </a>{" "}
        for storing all of our users' files.
        <br />
        This file storage complies with a massive array of{" "}
        <a
          href="https://cloud.google.com/security/compliance/"
          target="_blank"
          rel="noopener noreferrer"
        >
          regulations
        </a>
        .<br />
        Using Google's services, we ensure our data is encrypted in-transit, and
        at-rest.
        <br />
        All requests to Fieldscope &amp; Google's services are negotiated over
        an <strong>HTTPS</strong> connection, and are only possible once{" "}
        <strong>authenticated.</strong>
        <br />
        Fieldscope secures files such that they are available only to those who
        have been given <strong>explicit</strong> access by our users.
      </Fragment>
    ),
  },
  {
    title: "How can I delete my files?",
    content: (
      <Fragment>
        Fieldscope’s Workspace offers you two options once you’re done working
        with your files:
        <ul>
          <li>
            <strong> Archiving:</strong> Allows you to remove files from the
            workspace to avoid cluttering of redundant files. In the same way
            that you would archive emails from your inbox but not delete them
            entirely, the Archive option helps you keep your Workspace clean and
            easy to navigate!
          </li>
          <li>
            <strong>Permanently Delete:</strong> When you want to delete your
            files from Fieldscope altogether, you can choose to Permanently
            Delete your Archived files.
            <br />
            <em>
              Use this option carefully and ensure you have a back-up elsewhere.
            </em>
          </li>
        </ul>
        You can only use either of these options when there are no active
        assignments linked to those files i.e. your Provider is not working on
        them.
        <br />
        Fieldscope is built on the Google Cloud platform and you can read how
        file deletion is handled{" "}
        <a
          href="https://cloud.google.com/security/deletion/"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </Fragment>
    ),
  },
  {
    pretitle: "PRICING & PAYMENTS",
    title: "What are the costs associated with using Fieldscope?",
    content: (
      <Fragment>
        <ul>
          <li>Signing-up on Fieldscope is completely free.</li>
          <li>
            Costs for services are{" "}
            <a href="#/CLIENT/HOW_MUCH_DO_THE_PROVIDERS_CHARGE">
              set by the provider
            </a>{" "}
            on an individual basis.
          </li>
        </ul>
        A service fee is charged on all transactions which allows us to continue
        supporting the social-sector in finding and working with quality talent
        on our remote-work platform.
        <br />
        <strong>
          Service Fees for:
          <br />
        </strong>
        <blockquote style={{ paddingTop: "0px" }}>
          <strong>GST-registered providers</strong> is 10%. Additional GST
          charges will apply.
          <br />
          <strong>Non-GST registered providers</strong> is 11.8%. No GST charges
          apply.
          <br />
          Payment gateway charges are <strong>included</strong> in the service
          fee for both cases.
          <br />
        </blockquote>
        If you have further questions, please email{" "}
        <a href="mailto:payments@fieldscope.in">payments@fieldscope.in</a>
      </Fragment>
    ),
  },
  {
    title: "How much do the providers charge?",
    content: (
      <Fragment>
        On our marketplace model, providers set their own rates and clients can
        determine who meets their specific needs. The costs vary depending on
        the provider’s experience, difficulty of content and the language pairs
        they offer. For our current pool of providers you can expect the
        following ranges across a range of languages and experiences:
        <ul>
          <li>Translation: ₹0.80/word to ₹4/word</li>
          <li>Transcription: ₹40/minute to ₹80/minute</li>
        </ul>
        These are only estimates! Final rates are always negotiated with the
        provider. If a rate isn’t listed on the provider profile, please connect
        with them to get more information.
      </Fragment>
    ),
  },
  {
    title: "How do payments work on the platform?",
    content: (
      <Fragment>
        We’ve integrated an online payment gateway which simplifies invoicing,
        tracking and payments for both clients and providers. <br />
        Payments are securely made online using any of the popular payment
        methods such as UPI, Credit &amp; Debit cards, Netbanking, and Mobile
        Wallets.
        <br />
        If your organization cannot make online payments, we can also facilitate
        offline-payments through bank transfers.
      </Fragment>
    ),
  },

  {
    pretitle: "QUALITY ASSURANCE",
    title: "How can I be assured of the quality of your providers?",
    content: (
      <Fragment>
        Our providers go through a strict process of quality assurance including
        a one-on-one interview, verified reference checks for past work with
        reputed organizations and tester tasks for translation if needed.
        <br />
        Our{" "}
        <a
          href="https://medium.com/fieldscope-blog/introducing-reviews-on-fieldscope-d11123d66d7b"
          target="_blank"
          rel="noopener noreferrer"
        >
          reviews
        </a>{" "}
        feature helps us evaluate and maintain our Provider pool based on
        ongoing client feedback.
      </Fragment>
    ),
  },
  {
    title: "Can I “Test” or try a provider out before hiring them?",
    content: (
      <Fragment>
        We try to ensure that Fieldscope’s provider community is best suited for
        your work needs. We understand if you wish to try someone out for
        compatibility before a formal contract. To hire a provider on a test or
        trial basis, here’s what we recommend:
        <ol>
          <li>
            <strong>SEND A TEST CONTRACT</strong>
            <br />
            <ul>
              <li>Propose a Contract to your provider</li>
              <li>Specify it’s a “Test Contract” in the Title</li>
              <li>
                A contract here only implies that you’re agreeing on what the
                client’s rates are. There are no payment obligations at this
                point.
              </li>
            </ul>
          </li>
          <li>
            <strong>SEND A TEST ASSIGNMENT</strong>
            <br />
            <ul>
              <li>Upload your test sample on WORKSPACE</li>
              <li>
                Test samples should ideally not exceed 500 words for
                translation, and 15 min-clip for transcription if this is not
                being compensated. If your team has a trial budget, you may come
                to an agreement on the volume of trial material with your
                provider.
              </li>
              <li>
                Assign the work to the provider after they’ve accepted the
                contract
              </li>
            </ul>
          </li>
          <li>
            <strong>ASSIGNMENT INFO FOR TEST CONTENT</strong>
            <br />
            <ul>
              <li>
                Costs for assignments are calculated by word count and durations
                of the relevant files, so setting those limits to “0” ensures no
                amount owed.
              </li>
              <li>For translation: Enter "0" in the word count field</li>
              <li>For transcription: Enter “0” in the duration field</li>
            </ul>
          </li>
          <li>
            <strong>REPLACE CONTRACT</strong>
            <ul>
              <li>
                If you’re happy with your provider’s work and would like to
                officially hire them, you can simply Replace the Test Contract
                and resume the process to assign real work!
              </li>
            </ul>
          </li>
        </ol>
      </Fragment>
    ),
  },
  {
    title:
      "What happens if the provider’s work is not up to my standard of quality?",
    content: (
      <Fragment>
        We work hard to ensure that the providers on Fieldscope are experienced,
        top quality and diligent. If the work submitted is not up to your
        standard, we encourage you to have a discussion with your provider on
        what aspects of the work need to be improved. This will give them a
        chance to refine the work and potentially learn something new. If you
        feel like this still doesn’t resolve the issue, you can contact us to
        address the matter personally.
      </Fragment>
    ),
  },
];
