import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "@reach/router";
import { GENERAL, CLIENT, PROVIDER } from "src/components/faq";

import TopBar from "src/components/topbar";
import { ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles({
  root: {
    marginTop: 64,
    "& .MuiAccordionSummary-content a": {
      color: "unset",
      textDecoration: "none",
    },
  },
});

enum FAQSections {
  GENERAL = "GENERAL",
  CLIENT = "CLIENT",
  PROVIDER = "PROVIDER",
}

const DocsForFAQ = {
  [FAQSections.GENERAL]: GENERAL,
  [FAQSections.CLIENT]: CLIENT,
  [FAQSections.PROVIDER]: PROVIDER,
};

const key_from_title = (title) =>
  title
    .replace(/[\W]/g, "_")
    .replace(/_+/g, "_")
    .replace(/_$/g, "")
    .toUpperCase();

export default function FAQ() {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const [section_hash, ques_hash] = location.hash.substring(1).split("/");
  const section_from_hash = FAQSections[section_hash];

  const [activeTab, setActiveTab] = useState(
    section_from_hash || FAQSections.GENERAL
  );

  const tabDocs = DocsForFAQ[activeTab];

  function tabChange(quesKey) {
    navigate(`#${activeTab}/${quesKey}`);
  }
  useEffect(() => {
    if (section_from_hash !== activeTab) {
      // debugger;
      console.log(
        "setting loc",
        section_from_hash,
        location.hash,
        "-->",
        activeTab
      );
      navigate(`#${activeTab}`);
    }
  }, [section_from_hash, activeTab]);
  return (
    <>
      <TopBar />
      <Paper className={classes.root} elevation={0}>
        <Tabs
          value={activeTab}
          onChange={(e, v) => setActiveTab(v)}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {Object.values(FAQSections).map((section) => (
            <Tab key={section} value={section} label={section} />
          ))}
        </Tabs>
        <Paper style={{ padding: "1em" }} elevation={0}>
          {tabDocs.map((doc) => {
            const quesKey = key_from_title(doc.title);
            return (
              <>
                {doc.pretitle && (
                  <h3 style={{ textAlign: "center" }}>{doc.pretitle}</h3>
                )}
                <Accordion
                  id={`${activeTab}/${quesKey}`}
                  key={quesKey}
                  onChange={() => tabChange(quesKey)}
                  defaultExpanded={quesKey === ques_hash}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <a href={`#${activeTab}/${quesKey}`}>
                      <Typography variant="subtitle1">{doc.title}</Typography>
                    </a>
                  </AccordionSummary>
                  <AccordionDetails className="multiline-left-center">
                    {doc.content}
                  </AccordionDetails>
                </Accordion>
              </>
            );
          })}
        </Paper>
      </Paper>
    </>
  );
}
