import { Chip } from "@material-ui/core";
import React, { Fragment } from "react";

export const PROVIDER = [
  {
    pretitle: "BEFORE SIGNING-UP",
    title: "What kind of work can I expect on Fieldscope?",
    content: (
      <Fragment>
        Fieldscope only activates projects that have a social and cultural
        development focus.
        <li>
          Our clients come from a diverse mix of non-profits, social
          enterprises, academic institutions, think tanks, development
          consulting or communications agencies.
        </li>
        <li>
          Currently, we are actively servicing translation and transcription
          work:
          <ul>
            <li>
              Translation projects can include anything from impact tools,
              reports, curriculum, program content or cultural products that are
              essential to a social organization’s work.
            </li>
            <li>
              Transcription assignments vary from data transcriptions of
              development research, media transcriptions for documentaries or
              conferences.
            </li>
          </ul>
        </li>
      </Fragment>
    ),
  },
  {
    title: "How does compensation work?",
    content: (
      <Fragment>
        On Fieldscope’s marketplace model, we respect your autonomy to decide
        what you want to charge. Rates vary across language pairs and level of
        experience. Here’s a snapshot of what Fieldscope providers are currently
        charging:
        <ul>
          <li>
            For Translation, the hiring average rate is ₹1.50/word (Range:
            ₹0.80/word to ₹4/word)
          </li>
          <li>
            For Transcription, the hiring average rate is ₹60/minute (Range
            ₹40/minute to ₹80/minute)
          </li>
        </ul>
        We recommend that you do your own research as well to make sure you feel
        comfortable and confident with your rates. Otherwise we're always here
        to guide you!
      </Fragment>
    ),
  },

  {
    title: "Why can’t I see any projects when I sign-up?",
    content: (
      <Fragment>
        On Fieldscope, clients browse provider profiles based on their project
        needs. If they are interested in hiring you, you will be notified via
        email and the portal. To make your profile stand out more, write to us
        for Provider Profile Tips.
      </Fragment>
    ),
  },

  {
    pretitle: "AFTER PROFILE ACTIVATION",
    title: "Helpful tips for Profile Creation",
    content: (
      <Fragment>
        <li>
          Avoid lengthy blurbs and use your profile to highlight experiences
          relevant to the skill you’re offering.
        </li>
        <li>
          Use <strong>Domain</strong> tags to highlight your sectoral interests
          and expertise to potential clients. You can find these under your
          Settings &gt; Personal Info.
        </li>
        <li>
          We suggest uploading a profile picture. Profiles with photos help
          establish trust with clients, compared to a blank profile, enhancing
          your chance for a hire!
        </li>
      </Fragment>
    ),
  },

  {
    title: "Callback Request Process",
    content: (
      <Fragment>
        <li>
          Clients who are interested in your profile will send you callback
          requests through the portal.
        </li>
        <li>
          You will also receive an email notifying you about this. Make sure
          you’re checking those spam/promotions folders in your email in case
          important updates are at risk of getting lost.
        </li>
        <li>
          Responding quickly will increase your chance of landing that project.
        </li>
        <li>
          Use this conversation to understand the work, timelines, rates and
          other requirements clearly.
        </li>
        <strong>
          When you’ve decided to accept the work, request the client to send you
          a contract on Fieldscope.
        </strong>
      </Fragment>
    ),
  },

  {
    title: "Contracts on Fieldscope",
    content: (
      <Fragment>
        Your Contract on Fieldscope is how you indicate your agreement to the
        rates, scope of work, timelines and any other requirements with the
        client and to Fieldscope. If you want some specific clauses included,
        make sure you have those stated in the contract “terms” in discussion
        with the client.
      </Fragment>
    ),
  },

  {
    title: "Tests or Samples for Clients",
    content: (
      <Fragment>
        Clients may request to see samples of your past work during the callback
        process, so keep those handy as long as you have consent for sharing
        them. If the client wants to try-out a “test” sample,{" "}
        <a
          href="/faq#/CLIENT/CAN_I_TEST_OR_TRY_A_PROVIDER_OUT_BEFORE_HIRING_THEM"
          target="_blank"
          rel="noopener noreferrer"
        >
          here's
        </a>{" "}
        how we suggest they move forward. It is completely up to your discretion
        on whether you want to accept sample-tests or not. Often a test is a
        good way to explore fit for both sides!
      </Fragment>
    ),
  },
  {
    pretitle: "Payments & Invoicing",
    title: "How does invoicing work?",
    content: (
      <Fragment>
        We have integrated a payment system to simplify billing and invoicing
        for you!
        <ol>
          <li>
            Clients will review your work and marks assignments as{" "}
            <Chip label="COMPLETED" color="primary" />
          </li>
          <li>
            Assignments will appear in the{" "}
            <a
              href="https://medium.com/fieldscope-blog/flexible-invoices-for-flexible-work-6d4f4186c829"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>Billing Tab:</strong>
            </a>
            <ul>
              <li>Select assignments you would like to invoice</li>
              <li>Make any adjustments as necessary</li>
              <li>Generate and send the invoice to the client</li>
            </ul>
          </li>
          <li>
            Clients will make payments using our online payment gateway. Funds
            are transferred to your account in 2-3 business days.
            <ul>
              <li>
                In the rare event that a client cannot make online payments
                please let{" "}
                <a href="mailto:payments@fieldscope.in">
                  payments@fieldscope.in
                </a>{" "}
                know. In these cases, you can download and send the invoices in
                PDF form to clients.
              </li>
            </ul>
          </li>
        </ol>
      </Fragment>
    ),
  },
  {
    title: "How does Fieldscope's Service Fee affect my rates?",
    content: (
      <Fragment>
        What you charge is what you get! Our service fee does not take away from
        your compensation.
        <ul>
          <li>
            When you generate your invoice through Fieldscope, this{" "}
            <a
              href="/faq#/CLIENT/WHAT_ARE_THE_COSTS_ASSOCIATED_WITH_USING_FIELDSCOPE"
              target="_blank"
              rel="noopener noreferrer"
            >
              service fee
            </a>{" "}
            is automatically added.
          </li>
          <li>
            Client are thus billed for the total amount that includes both your
            professional fees and our service charges.
          </li>
          <li>
            Upon payment through the online platform, Fieldscope forwards your
            professional fees directly to your account.
          </li>
        </ul>
      </Fragment>
    ),
  },
];
