import React, { Fragment } from "react";

export const GENERAL = [
  {
    title: "What is Fieldscope?",
    content: (
      <Fragment>
        Fieldscope is an online marketplace and task-management platform that
        helps the social-impact sector find trusted service providers for their
        short-term project needs. We’re an early stage social enterprise
        currently in beta mode.
      </Fragment>
    ),
  },
  {
    title: "What does “Beta” version mean?",
    content: (
      <Fragment>
        A Beta product means we’re a work in progress! We are slowly integrating
        new features to meet our users’ needs.
        <br />
        Features in the pipeline include:
        <br />
        <ul>
          <li>On-platform messaging</li>
          <li>
            Additional provider services to streamline your remote-work needs in
            one space
          </li>
        </ul>
        If you get stuck or discover a problem, send us a message from the
        support button on the upper right corner of the website. This only helps
        us improve our beta product and give our users a better experience in
        the future.
      </Fragment>
    ),
  },
  {
    title: "Why does Fieldscope exist?",
    content: (
      <Fragment>
        We’re excited to be a part of a changing ecosystem for social impact
        that’s driven by efficiency, optimization and creating opportunity.
        <br />
        <ul>
          <li>
            As a client, talent acquisition through on-the-ground partners and
            other network referrals may not always result in the optimal match
            for quality and cost.
          </li>
          <li>
            As a service provider, limited geographic scope or lack of know-how
            stunts professional growth.
          </li>
        </ul>
        Fieldscope’s marketplace model wants to help bridge this gap so both
        sides are connected seamlessly allowing for better discoverability,
        efficient communication, faster task and process management and secure
        payments. This leaves more time for the people in this sector to do what
        they’re passionate about- making an impact.
      </Fragment>
    ),
  },
];
